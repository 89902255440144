<template>
  <LoaderPlaceholder v-if="loading" />
  <div v-else>
    <vue-good-table
      :columns="columns"
      :rows="displayedEntries"
      :pagination-options="{
        enabled: true,
        perPage: dense ? 10 : 20,
        perPageDropdown: [20, 50, 100],
        dropdownAllowAll: false,
      }"
      :compact-mode="dense"
      :search-options="{ enabled: true }"
      style-class="vgt-table"
    >
      <template #table-row="props">
        <span v-if="props.column.field === 'name'">
          <router-link
            :to="getEntryLink(props.row)"
            target="_blank"
          >
            <div class="text-subtitle-1">
              {{ props.row.name }}
            </div>
          </router-link>
        </span>
        <span
          v-else-if="props.column.field === 'linked'"
          class="m-n4"
        >
          <v-checkbox-btn
            :model-value="props.row.linked"
            density="compact"
            @click.stop="handleSwitchLink($event, props.row.uuid)"
          />
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';
import { mapGeoBaseTypeId } from '@/config/geoBaseTypes';
import { tr } from 'vuetify/locale';

export default {
  name: 'GeoBaseLinkTable',
  props: {
    sourceUuid: String,
    sourceSlug: String,
    controlledEntries: {
      type: Array,
      default: null,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      entries: [],
      loading: true,
      columns: [
        {
          label: 'Linked',
          field: 'linked',
          type: 'boolean',
          width: '90px',
          sortable: true,
        },
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: !this.dense,
          },
        },
        ...(this.dense
          ? []
          : [
            {
              label: 'Type',
              field: 'geoBaseType',
              filterOptions: {
                enabled: true,
              },
            },
          ]),
      ],
    };
  },
  computed: {
    tr() {
      return tr;
    },
    displayedEntries() {
      return this.entries.map((e) => ({
        ...e,
        linked: this.controlledEntries !== null ? this.controlledEntries.includes(e.uuid) : e.linked,
        name: e.name,
        geoBaseType: e.geoBaseType,
      }));
    },
  },
  mounted() {
    this.loadLinks();
    //
    // if (this.controlledEntries === null) {
    // } else {
    //   this.loading = false;
    // }
  },
  methods: {
    handleSwitchLink(event, entryUuid) {
      event.preventDefault();
      if (this.controlledEntries !== null) {
        this.$emit('switch-link', entryUuid);
      } else {
        axios
          .post(
            `${getBackendAdminHost(this.$store)}/admin/link-geo-base/${this.sourceUuid}/${entryUuid}`,
            {},
            { withCredentials: true },
          )
          .then(() => {
            const changedEntry = this.entries.find((d) => d.uuid === entryUuid);
            changedEntry.linked = !changedEntry.linked;
            this.entries = [...this.entries]; // update
          })
          .catch(() => {
            this.submitError = 'Error switching active state';
          });
      }
    },
    loadLinks() {
      axios
        .get(
          this.sourceUuid ? `${getBackendAdminHost(this.$store)}/admin/link-geo-base/get-meta/${this.sourceUuid}`
            : `${getBackendAdminHost(this.$store)}/admin/link-geo-base/get-meta-all-possible/${this.sourceSlug}`,
          { withCredentials: true },
        )
        .then((response) => {
          this.processGetResponse(response.data);
          this.loading = false;
        })
        .catch(() => {
          this.submitError = 'Error loading entries';
        });
    },
    processGetResponse(responseData) {
      const { linkedEntities, unlinkedEntities } = responseData;
      this.entries = [
        ...linkedEntities.map((l) => ({
          ...l,
          linked: true,
          geoBaseType: mapGeoBaseTypeId(l.geoBaseTypeId).name,
        })),
        ...unlinkedEntities.map((u) => ({
          ...u,
          linked: false,
          geoBaseType: mapGeoBaseTypeId(u.geoBaseTypeId).name,
        })),
      ];
    },
    getEntryLink(row) {
      return `/${mapGeoBaseTypeId(row.geoBaseTypeId).path}/${row.uuid}`;
    },
  },
};
</script>

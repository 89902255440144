<template>
  <GeoEntityView
    ref="geoEntityView"
    :geo-base-type="geoBaseType"
    info-text-general="All experiences"
    disable-coordinates
    disable-stay-duration
  >
    <template
      v-if="$route.params.uuid!=='new'"
      #linksTab
    >
      <page-top-area
        header-text="Links"
        :info-text="'Link your experience'"
      />
      <GeoBaseLinkTable
        source-slug="experience"
        :source-uuid="$route.params.uuid"
      />
    </template>
    <template
      v-else
      #firstContent
    >
      <GeoBaseLinkTable
        source-slug="experience"
        :controlled-entries="linkedEntities"
        :dense="true"
        @switch-link="handleSwitchLink"
      />
    </template>
  </GeoEntityView>
</template>

<script>

import GeoEntityView from '@/views/GeoEntityView.vue';
import GeoBaseLinkTable from '@/components/GeoBaseLinkTable.vue';
import { geoBaseTypes } from '@/config/geoBaseTypes';

export default {
  components: {
    GeoEntityView,
    GeoBaseLinkTable,
  },
  beforeRouteLeave(to, from, next) {
    this.$refs.geoEntityView.beforeRouteLeave(to, from, next);
  },
  data() {
    return ({
      geoBaseType: geoBaseTypes.EXPERIENCE,
      linkedEntities: [],
      additionalFields: this.createAdditionalFields(),
    });
  },
  watch: {
    linkedEntities(newVal, oldVal) {
      this.additionalFields = this.createAdditionalFields(newVal);
      console.log(this.additionalFields);
    },
  },
  methods: {
    handleSwitchLink(entryUuid) {
      if (this.linkedEntities.includes(entryUuid)) {
        this.linkedEntities = this.linkedEntities.filter((e) => e !== entryUuid);
      } else {
        this.linkedEntities.push(entryUuid);
      }
      this.additionalFields = this.createAdditionalFields(this.linkedEntities);
    },
    createAdditionalFields(newVal) {
      return [
        {
          name: 'linkedEntities',
          value: newVal,
        },
      ];
    },
  },
};
</script>

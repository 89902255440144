<template>
  <LoaderPlaceholder
    v-if="loading"
  />
  <div v-else-if="entries.length === 0">
    <v-card>
      <v-card-text>
        <v-row>
          <v-col>
            No {{ geoBaseType.name }}s found.
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
  <div v-else>
    <vue-good-table
      :columns="columns"
      :rows="entries"
      :pagination-options="{
        enabled: true,
        perPage: 30,
        perPageDropdown: [15, 30, 50, 100],
        dropdownAllowAll: false,
      }"
      :search-options="{
        enabled: true,
      }"
      style-class="vgt-table"
    >
      <template #table-row="props">
        <span v-if="props.column.field === 'name'">
          <router-link :to="'/' + geoBaseType.path + '/' + props.row.uuid">
            <div>
              <v-tooltip
                v-if="getMissingInfoTooltip(props.row)"
                :text="getMissingInfoTooltip(props.row)"
                location="start"
                :is-visible="props.row.infoCompletness < 1.0"
              >
                <template #activator="{ props : tooltipProps }">
                  <span
                    v-bind="tooltipProps"
                    :class="{
                      'text-subtitle-1': true,
                      'font-italic': props.row.infoCompletness < 1.0,
                    }"
                  >
                    {{ props.row.name }}
                  </span>
                </template>
              </v-tooltip>
              <span v-else>
                {{ props.row.name }}
              </span>
            </div>
          </router-link>
        </span>
        <span
          v-else-if="props.column.field === 'active'"
          class="m-n4 float-end"
        >
          <v-checkbox-btn
            v-model="props.row.active"
            density="compact"
            @click.stop="handleSwitchActive($event, props.row.uuid)"
          />
        </span>
        <span
          v-else-if="props.column.field === 'swiping'"
          class="m-n4 float-end"
        >
          <v-checkbox-btn
            v-model="props.row.swiping"
            density="compact"
            @click.stop="handleSwitchSwiping($event, props.row.uuid)"
          />
        </span>
        <span v-else-if="props.column.field === 'regions'">
          <v-chip
            v-for="(region) in props.row.regions"
            :key="region.uuid"
            color="primary"
            class="ma-1"
            small
          >
            {{ region.name }}
          </v-chip>
        </span>
        <span v-else-if="props.column.field === 'linkedGeoBases'">
          <v-chip
            v-for="(linkedGeoBase) in props.row.linkedGeoBases"
            :key="linkedGeoBase.uuid"
            color="primary"
            class="ma-1"
            small
          >
            {{ linkedGeoBase.name }}
          </v-chip>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
  <AiWizardCompleteDialog
    :open="wizardModalOpen"
    :on-abort="handleCloseWizard"
    :initial-entity-name="name"
    :initial-request-type="wizardRequestType"
    :geo-base-type="geoBaseType"
  />
  <RequestErrorNotification :error="submitError" />
</template>

<script>
import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';
import AiWizardCompleteDialog from '@/components/AiWizardCompleteDialog.vue';
import { completeWizardRequestTypes } from '@/config/completeWizardRequestTypes';
import { tr } from 'vuetify/locale';
import { bool } from 'yup';

export default {
  components: { AiWizardCompleteDialog },
  props: {
    geoBaseType: {
      type: Object,
      required: true,
    },
    showLinkedGeoBasesColumn: {
      type: bool,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      submitError: null,
      requestTypes: completeWizardRequestTypes,
      wizardModalOpen: false,
      entries: [],
      regionsList: [],
      linkedGeoBasesList: [],
    };
  },
  computed: {
    tr() {
      return tr;
    },
    columns() {
      return [
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Country',
          field: 'countryName',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Regions',
          field: 'regions',
          filterOptions: {
            enabled: true,
            filterDropdownItems: this.regionsList,
            placeholder: 'Region',
            filterFn: (cellValue, searchTerm) => {
              if (!searchTerm) return true;
              if (!cellValue) return false;
              return cellValue.some((region) => region.name === searchTerm);
            },
          },
          filterType: 'dropdown',
        },
        {
          label: 'Public',
          field: 'active',
          type: 'boolean',
        },
        {
          label: 'Swiping',
          field: 'swiping',
          type: 'boolean',
        },
        {
          label: 'Rating',
          field: 'rating',
          type: 'number',
        },
        ...this.showLinkedGeoBasesColumn ? [
          {
            label: 'Linked',
            field: 'linkedGeoBases',
            filterOptions: {
              enabled: true,
              filterDropdownItems: this.linkedGeoBasesList,
              placeholder: 'Associations',
              filterFn: (cellValue, searchTerm) => {
                if (!searchTerm) return true;
                if (!cellValue) return false;
                return cellValue.some((region) => region.name === searchTerm);
              },
            },
            filterType: 'dropdown',
          },
        ] : [],
      ];
    },
  },
  mounted() {
    this.axios
      .get(`${getBackendAdminHost(this.$store)}/admin/${this.geoBaseType.path}`, { withCredentials: true })
      .then((response) => {
        this.entries = response.data;
        this.loading = false;
        // Extract regions
        const regionsSet = new Set();
        const linkedGeoBasesSet = new Set();
        this.entries.forEach((entry) => {
          if (entry.regions) {
            entry.regions.forEach((region) => {
              regionsSet.add(region.name);
            });
          }
          if (entry.linkedGeoBases) {
            entry.linkedGeoBases.forEach((linkedGeoBase) => {
              linkedGeoBasesSet.add(linkedGeoBase.name);
            });
          }
          // eslint-disable-next-line no-param-reassign
          entry.infoCompletness = (Number(Boolean(entry.teaser && entry.teaser.length)) + entry.hasDescription + entry.hasImages + entry.hasTags) / 4.0;
        });
        this.regionsList = Array.from(regionsSet);
        this.linkedGeoBasesList = Array.from(linkedGeoBasesSet);
      })
      .catch((e) => {
        console.error(e);
        this.submitError = 'Error during request';
      });
  },
  methods: {
    handleSwitchActive(event, entryUuid) {
      event.preventDefault();
      axios
        .post(
          `${getBackendAdminHost(this.$store)}/admin/${this.geoBaseType.path}/${entryUuid}/switch-active`,
          {},
          { withCredentials: true },
        )
        .then(() => {
          const changedEntry = this.entries.find((d) => d.uuid === entryUuid);
          changedEntry.active = !changedEntry.active;
          this.entries = [...this.entries]; // update
        })
        .catch(() => {
          this.submitError = 'Error switching active state';
        });
    },
    handleSwitchSwiping(event, entryUuid) {
      event.preventDefault();
      axios
        .post(
          `${getBackendAdminHost(this.$store)}/admin/${this.geoBaseType.path}/${entryUuid}/switch-swiping`,
          {},
          { withCredentials: true },
        )
        .then(() => {
          const changedEntry = this.entries.find((d) => d.uuid === entryUuid);
          changedEntry.swiping = !changedEntry.swiping;
          this.entries = [...this.entries]; // update
        })
        .catch(() => {
          this.submitError = 'Error switching swiping state';
        });
    },
    handleOpenWizard(requestType) {
      this.wizardRequestType = requestType;
      this.wizardModalOpen = true;
    },
    handleCloseWizard() {
      this.wizardModalOpen = false;
    },
    getMissingInfoTooltip(entry) {
      const missingInfo = [];
      if (!entry.teaser || !entry.teaser.length) {
        missingInfo.push('Teaser');
      }
      if (!entry.hasDescription) {
        missingInfo.push('Description');
      }
      if (!entry.hasImages) {
        missingInfo.push('Images');
      }
      if (!entry.hasTags) {
        missingInfo.push('Tags');
      }
      if (missingInfo.length === 0) {
        return null;
      }
      return `Missing information: ${missingInfo.join(', ')}`;
    },
  },
};
</script>

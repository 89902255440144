<template>
  <v-row v-if="images">
    <v-col
      v-for="{path, uuid, active, order} in images"
      :key="path + order"
      :cols="12"
      :md="6"
      :lg="4"
    >
      <v-img
        :src="getImagePath(path)"
        :style="{cursor: 'pointer', border: (uuid === selectedImageUuid ? '3px solid orange' : '')}"
        @click="handleSelectImage(uuid)"
      />
      <v-container
        v-if="uuid != null && selectedImageUuid === uuid"
        class="flex-row d-flex pb-0"
      >
        <v-text-field
          v-model="imageLinks[uuid].copyright"
          label="Text"
          placeholder=""
          class="mr-1"
          size="small"
        />
        <v-btn
          :disabled="isSaveDisabled || loading"
          color="primary"
          class="ml-2 mt-3"
          @click="handleSaveCopyright(uuid)"
        >
          Save
        </v-btn>
      </v-container>
      <v-container
        v-if="uuid != null && selectedImageUuid === uuid"
        class="flex-row d-flex pt-0 justify-center"
      >
        <v-btn
          v-if="images.length > 1"
          class="mx-1"
          color="primary"
          size="small"
          @click="handleSetFirst(uuid)"
        >
          As first
        </v-btn>
        <v-btn
          v-if="path.includes('http')"
          :disabled="loading"
          class="mx-1"
          size="small"
          @click="handleRemoveFile(uuid)"
        >
          Remove
        </v-btn>
        <v-btn
          v-else-if="active"
          :disabled="loading"
          class="mx-1"
          size="small"
          @click="handleDeactivate(uuid)"
        >
          Deactivate
        </v-btn>
        <v-btn
          v-else-if="!active"
          :disabled="loading"
          class="mx-1"
          color="primary"
          size="small"
          @click="handleActivate(uuid)"
        >
          Activate
        </v-btn>
      </v-container>
    </v-col>
  </v-row>
  <v-row class="mt-8">
    <v-container
      class="d-flex align-center"
      style="max-width: 700px;"
    >
      <div
        class="text-subtitle-1 mt-3 mr-4 d-flex align-center"
        style="align-self: flex-start;"
      >
        New images (max. 5):
      </div>
      <v-file-input
        v-model="fileUpload"
        placeholder="Upload new images (max. 5)"
        accept="image/*"
        truncate-length="15"
        multiple
        :style="{ width: '150px' }"
        class="mr-4"
      />
      <v-btn
        :disabled="!fileUpload || loading"
        color="primary"
        :loading="loading"
        prepend-icon="mdi-upload"
        style="align-self: flex-start;"
        class="mt-3"
        @click="handleUploadFile"
      >
        Upload images
      </v-btn>
    </v-container>
  </v-row>
  <RequestErrorNotification :error="submitError" />
</template>

<script>
import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';

export default {
  name: 'ImageUpload',
  props: {
    uploadPath: String,
    reloadFunction: Function,
    v$: Object,
    images: Array,
  },
  data() {
    return {
      loading: false,
      newImages: [],
      fileUpload: '',
      selectedImageUuid: null,
      submitError: null,
      imageLinks: this.initializeImageLinks(this.images),
      originalImageLinks: this.initializeImageLinks(this.images),
    };
  },
  computed: {
    isSaveDisabled() {
      return JSON.stringify(this.imageLinks) === JSON.stringify(this.originalImageLinks);
    },
  },
  watch: {
    images: {
      handler(newImages) {
        this.imageLinks = this.initializeImageLinks(newImages);
        this.originalImageLinks = this.initializeImageLinks(newImages);
      },
      deep: true,
    },
  },
  methods: {
    initializeImageLinks(images) {
      return images.reduce((acc, image) => {
        acc[image.uuid] = { copyright: image.copyright };
        return acc;
      }, {});
    },
    getImagePath(path) {
      if (path.includes('http')) {
        return path;
      }
      return `http://localhost/${path}`;
    },
    handleUploadFile() {
      if (this.fileUpload.length > 5) {
        this.submitError = 'You can only upload up to 5 images at a time';
        this.fileUpload = '';
        return;
      }

      this.loading = true;
      const uploadPromises = this.fileUpload.map((file) => {
        const formData = new FormData();
        formData.append('file', file);
        return axios.post(this.uploadPath, formData, {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      });

      Promise.all(uploadPromises)
        .then((responses) => {
          responses.forEach((response) => this.reloadFunction(response));
        })
        .catch(() => {
          this.submitError = 'Error during request';
        })
        .finally(() => {
          this.loading = false;
          this.fileUpload = '';
        });
    },
    handleSelectImage(uuid) {
      this.selectedImageUuid = uuid;
    },
    handleSetFirst(uuid) {
      this.loading = true;
      axios.post(`${getBackendAdminHost(this.$store)}/admin/image-config/${uuid}/first`, {}, {
        withCredentials: true,
      })
        .then(() => {
          this.reloadFunction();
        })
        .catch(() => {
          this.submitError = 'Error during request';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleDeactivate(uuid) {
      this.loading = true;
      axios.post(`${getBackendAdminHost(this.$store)}/admin/image-config/${uuid}/deactivate`, {}, {
        withCredentials: true,
      })
        .then(() => {
          this.reloadFunction();
        })
        .catch(() => {
          this.submitError = 'Error during request';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleActivate(uuid) {
      this.loading = true;
      axios.post(`${getBackendAdminHost(this.$store)}/admin/image-config/${uuid}/activate`, {}, {
        withCredentials: true,
      })
        .then(() => {
          this.reloadFunction();
        })
        .catch(() => {
          this.submitError = 'Error during request';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleRemoveFile(uuid) {
      this.loading = true;
      axios.delete(`${getBackendAdminHost(this.$store)}/admin/image/${uuid}`, {
        withCredentials: true,
      })
        .then(() => {
          this.reloadFunction();
        })
        .catch(() => {
          this.submitError = 'Error during request';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleSaveCopyright(uuid) {
      this.loading = true;
      const requestBody = {
        copyright: this.imageLinks[uuid].copyright,
      };
      axios.put(`${getBackendAdminHost(this.$store)}/admin/image/${uuid}/set-copyright`, requestBody, {
        withCredentials: true,
      })
        .then(() => {
          this.submitError = 'Copyright saved successfully';
          this.originalImageLinks = JSON.parse(JSON.stringify(this.imageLinks));
        })
        .catch(() => {
          this.submitError = 'Error during request';
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<template>
  <div>
    <page-top-area
      header-text="Experiences"
      info-text="Manage all experiences"
    >
      <template #button-area>
        <v-btn
          prepend-icon="mdi-plus-circle"
          variant="text"
          color="#4485ca"
          @click="handleCreate"
        >
          New experience
        </v-btn>
      </template>
    </page-top-area>
    <GeoEntityListView
      :geo-base-type="geoBaseType"
      :show-linked-geo-bases-column="true"
    />
  </div>
</template>

<script>

import GeoEntityListView from '@/views/GeoEntityListView.vue';
import PageTopArea from '@/components/PageTopArea.vue';
import { geoBaseTypes } from '@/config/geoBaseTypes';

export default {
  components: { PageTopArea, GeoEntityListView },
  data() {
    return {
      geoBaseType: geoBaseTypes.EXPERIENCE,
    };
  },
  methods: {
    handleCreate() {
      this.$router.push('/experience/new');
    },
  },
};
</script>
